import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import { getReportTotal } from '@/services/item';
import { Format } from '@/utils/format';
import { ProCard } from '@ant-design/pro-components';
import { Alert } from '@antd';
import { useRequest } from 'ahooks';
import { get } from 'lodash';
import { DetailQueryFilter } from '../../../detail/$id';
import { dict } from '@/hooks/useChangeLocale';

const styleProps = {
  headStyle: { paddingInlineStart: 5 },
  bodyStyle: { paddingInlineStart: 0, padding: '10px 20px' },
};

type ProdDetailIndexProps = {
  filter: DetailQueryFilter;
};
const ProdDetailIndex = ({ filter }: ProdDetailIndexProps) => {
  const { id } = filter;
  const { data: total, loading } = useRequest(
    () => {
      return getReportTotal({
        itemId: id,
        fromNow: true,
      });
    },

    { refreshDeps: [id] },
  );

  const items: StatisticItem[] = [
    {
      title: dict('PLAY_TOTAL'),
      value: Format.toAmountNumber(get(total, 'playCount')),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('AVG_PLAY_DURATION'),
      value: Format.toAmountNumber(get(total, 'avgPlayTimeSec', 0) / 60, 2),
      suffix: dict('STATIST_UNIT_MIN'),
    },
    {
      title: dict('LIKE'),
      value: Format.toAmountNumber(get(total, 'likeCount')),
    },
    {
      title: dict('RECOMMEND'),
      value: Format.toAmountNumber(get(total, 'favCount')),
    },
    {
      title: dict('COMMENT'),
      value: Format.toAmountNumber(get(total, 'commentCount')),
    },
    {
      title: dict('FELLOW'),
      value: Format.toAmountNumber(get(total, 'followCount')),
    },
    {
      title: dict('SHARE'),
      value: Format.toAmountNumber(get(total, 'shareCount')),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('COMPLETION_RATE'),
      value: Format.toAmountNumber(get(total, 'finishPlayRatio')),
    },
  ];

  return (
    <>
      <Alert
        showIcon
        message={dict('MATRIX_OPERATION_STATISTICS_TIP')}
        style={{ backgroundColor: 'transparent', border: 0 }}
        closable={false}
      />
      <ProCard {...styleProps}>
        <StatisticCardPanel items={items} columns={5} loading={loading} />
      </ProCard>
    </>
  );
};

export default ProdDetailIndex;
