import rank1 from '@/assets/rank1.svg';
import rank2 from '@/assets/rank2.svg';
import rank3 from '@/assets/rank3.svg';

const rankList = [rank1, rank2, rank3];

export const Rank = ({ index }: { index: number }) => {
  return (
    <div className="flex text-lg text-gray-500 justify-center">
      {index < 3 ? <img src={rankList[index]} style={{ width: 30 }} /> : `${index + 1}`}
    </div>
  );
};
