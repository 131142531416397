import { AccountAvator } from '@/components/AccountAvator';
import { FormCard } from '@/components/PageCard';
import { VideoCoverImg } from '@/components/VideoCoverImg';
import { ROUTE_PATH } from '@/constants/const';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getReportSummary } from '@/services/item';
import { DateUtils } from '@/utils/dateUtil';
import { Space, Spin, Table, Typography } from '@antd';
import { useRequest, useSafeState } from 'ahooks';
import { convertDbFilterToFilter, getSoretParams } from '../../../const';
import { downloadKsReportSummary } from '@/services/item/ks';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';
import { QueryReportParams } from '@/services/item/type';
// import { useAppContext } from '@/contexts/AppContext';

type ListPros = {
  params: RequestSearchParams;
};
const { Link } = Typography;

const List = ({ params }: ListPros) => {
  // const { user } = useAppContext();
  // const { message } = App.useApp();
  const [downloadParams, setDownloadParams] = useSafeState<RequestSearchParams>({});

  const { refresh: download, loading: downloadLoading } = useRequest(
    () => downloadKsReportSummary(downloadParams as QueryReportParams),
    { manual: true },
  );

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'publishTime', order: 'descend' } }) => {
      const { publish_time, tagValueIds, ...rest } = params;
      const [startTime, endTime] = publish_time ?? [];
      const downloadParams = {
        startTime,
        endTime,
        tagValueIds: tagValueIds?.map((v) => v[1]),
        ...getSoretParams(sorter),
        ...convertDbFilterToFilter(rest),
      };
      setDownloadParams(downloadParams);
      return getReportSummary({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params], debounceWait: 100 },
  );

  // const { run: updateTag, loading: updateTagLoading } = useRequest(
  //   async (itemId, tagIds) => {
  //     try {
  //       await updateItemTag(itemId, tagIds, user?.bid);
  //       message.success(dict('OPERATION_SUCCESS'));
  //       refresh();
  //     } catch (error) {
  //       message.error(dict('OPERATION_FAILURE'));
  //     }
  //   },
  //   {
  //     refreshDeps: [user],
  //     manual: true,
  //   },
  // );
  // const [AddTagModal, showTagModal] = useAddTagModal();

  const { getBtnPermissionConfig } = usePermission();
  const viewAuthConfig = getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.VIEW);

  return (
    <FormCard
      title={dict('LIST_WORKS')}
      style={{ marginBottom: 10 }}
      extra={
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      }
    >
      <Table
        {...tableProps}
        // loading={tableProps.loading || updateTagLoading}
        scroll={{ x: 1200, y: 500 }}
        columns={[
          getTableColumn({
            title: dict('COVER'),
            dataIndex: 'cover',
            width: 100,
            render: (url, record) => (
              <VideoCoverImg style={{ width: 80 }} itemId={record.itemId} src={record.coverOssUrl || url} />
            ),
          }),
          getTableColumn({
            title: dict('TITLE_OF_WORK'),
            dataIndex: 'title',
            width: 150,
            render: (value) => (
              <Typography.Paragraph ellipsis={{ rows: 2, tooltip: true }}>{value}</Typography.Paragraph>
            ),
          }),
          getTableColumn({
            title: dict('PUBLISH_ACCOUNT'),
            dataIndex: 'accountName',
            width: 150,
            render: (_, record) => <AccountAvator avaterUrl={record?.avatar} name={record?.accountName} />,
          }),
          getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),
          getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName' }),
          getTableColumn({ title: dict('DEALER_CODE'), dataIndex: 'distributorCode' }),
          // getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
          // getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
          // getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),
          // getTableColumn({
          //   title: dict('TAG'),
          //   dataIndex: 'tagInfoList',
          //   width: 200,
          //   ellipsis: true,
          //   render: (tagInfoList) =>
          //     isEmpty(tagInfoList) ? '-' : tagInfoList?.map((v) => `${v.tagName}-${v.tagValueName}`).join(','),
          // }),
          getTableColumn({ title: dict('PLAY_TOTAL'), dataIndex: 'playCount', sorter: true }),
          getTableColumn({ title: dict('LIKE_TOTAL'), dataIndex: 'likeCount', sorter: true }),
          getTableColumn({ title: dict('SHARE_TOTAL'), dataIndex: 'shareCount', sorter: true }),
          getTableColumn({ title: dict('COMMENT_TOTAL'), dataIndex: 'commentCount', sorter: true }),
          getTableColumn({
            title: dict('COMPLETION_RATE'),
            dataIndex: 'lpItemFinishPlayRatio',
            sorter: true,
            render: (_, record) => record.finishPlayRatio,
          }),
          getTableColumn({
            title: dict('PUBLISH_TIME'),
            dataIndex: 'publishTime',
            align: 'center',
            width: 120,
            sorter: true,
            defaultSortOrder: 'descend',
            render: (v) => DateUtils.formatDateTime(v),
          }),

          getTableColumn({
            title: dict('ACTION_OPERATE'),
            align: 'center',
            fixed: 'right',
            width: 120,
            render: (_, record) => {
              const url = `/${ROUTE_PATH.MATRIX}/${ROUTE_PATH.PRODUCTION}/${ROUTE_PATH.KUAISHOU}/${
                ROUTE_PATH.DETAIL
              }/${encodeURIComponent(record.itemId)}`;

              return (
                <Space>
                  <Link
                    title={dict('ACTION_VIEW_INTERACTIVE')}
                    {...viewAuthConfig}
                    onClick={() => window.open(`${BASE_PATH}${url}?type=video-detail`)}
                  >
                    {dict('ACTION_VIEW')}
                  </Link>
                  <Link
                    title={dict('ACTION_VIEW_COMMENT')}
                    {...viewAuthConfig}
                    onClick={() => window.open(`${BASE_PATH}${url}?type=comment`)}
                  >
                    {dict('ACTION_COMMENT')}
                  </Link>
                  {/* <Link
                    title={dict('ACTION_ADD_NAME')}
                    onClick={() => {
                      showTagModal(
                        record?.tagInfoList?.filter((v) => v.type == 'manual').map((v) => [v.tagId, v.tagValId]),
                        record?.tagInfoList?.filter((v) => v.type == 'automatic').map((v) => [v.tagId, v.tagValId]),
                        record.itemId,
                      );
                    }}
                  >
                    {dict('ACTION_ADD_NAME', { name: dict('TAG') })}
                  </Link> */}
                </Space>
              );
            },
          }),
        ]}
      />
      {/* <AddTagModal
        onTagSelect={(tagIds, targetId) => {
          updateTag(
            targetId,
            tagIds.map((v) => v[1]),
          );
        }}
      /> */}
    </FormCard>
  );
};

export default List;
