import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import { getReportTotal } from '@/services/item';
import { Format } from '@/utils/format';
import { useRequest } from 'ahooks';
import { get } from 'lodash';
import { DetailQueryFilter } from '.';
import { dict } from '@/hooks/useChangeLocale';

type ProdDetailIndexProps = {
  filter: DetailQueryFilter;
};
const ProdDetailIndex = ({ filter }: ProdDetailIndexProps) => {
  const { id } = filter;
  const { data: total, loading } = useRequest(
    () =>
      getReportTotal({
        itemId: id,
        fromNow: true,
      }),
    { refreshDeps: [id] },
  );

  const items: StatisticItem[] = [
    {
      title: dict('PLATY_TOTAL'),
      value: Format.toAmountNumber(get(total, 'playCount')),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('LIKE_COUNT_TOTAL'),
      value: Format.toAmountNumber(get(total, 'likeCount')),
      suffix: dict('STATIST_UNIT_GE'),
    },
    {
      title: dict('SHARE_COUNT_TOTAL'),
      value: Format.toAmountNumber(get(total, 'shareCount')),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('COMMENT_COUNT_TOTAL'),
      value: Format.toAmountNumber(get(total, 'commentCount')),
      suffix: dict('STATIST_UNIT_TIAO'),
    },
    {
      title: dict('COMPLETION_RATE'),
      value: Format.toAmountNumber(get(total, 'finishPlayRatio')),
      suffix: '',
    },
  ];

  return (
    <>
      <StatisticCardPanel items={items} loading={loading} />
    </>
  );
};

export default ProdDetailIndex;
