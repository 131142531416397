import ChartCore from '@/components/ChartCore';
import { getReportDaily } from '@/services/item';
import { useRequest } from 'ahooks';
import { DetailQueryFilter } from '.';
import { dict } from '@/hooks/useChangeLocale';

const getOption = (data, labels) => ({
  tooltip: {
    trigger: 'axis',
    formatter: function (params) {
      let tooltipText = `${params[0].name}<br/>`;
      for (let i = 0; i < params.length; i++) {
        const { seriesName } = params[i];
        let { value } = params[i];
        if (/率$/.test(seriesName)) {
          value = `${value?.toFixed(2)}%`;
        }

        tooltipText += `${params[i].marker + seriesName}: ${value}<br/>`;
      }
      return tooltipText;
    },
  },
  legend: {},
  grid: {
    top: 30,
    left: 50,
    right: 50,
    bottom: 60,
  },
  dataZoom: data.length > 10 ? [{ maxValueSpan: 15, minValueSpan: 8, start: 90 }] : undefined,
  xAxis: {
    type: 'category',
    boundaryGap: false,
    offset: 10,
    data: labels,
  },
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: '{value} ',
      },
    },
    {
      type: 'value',
      min: 0,
      max: 100,
      axisLabel: {
        formatter: '{value}%',
      },
    },
  ],
  series: Object.entries({
    playCount: dict('PLAY_TODAY'),
    likeCount: dict('LIKE_TODAY'),
    shareCount: dict('SHARE_TODAY'),
    commentCount: dict('COMMENT_TODAY'),
    finishPlayRatio: dict('COMPLETION_RATE'),
  }).map(([field, name]) => ({
    name,
    // smooth: true,
    areaStyle: {
      opacity: 0,
    },
    type: 'line',
    yAxisIndex: /率$/.test(name) ? 1 : 0,
    data: data.map((records) => records.reduce((total, record) => total + record[field], 0)),
  })),
});

const LineChart = ({ filter }: { filter: DetailQueryFilter }) => {
  const { id, rangeDate, ...rest } = filter;
  const [startDate, endDate] = rangeDate ?? [];

  const { data = {} } = useRequest(
    async () => {
      const { list } = await getReportDaily({
        itemId: id,
        ...rest,
        startDate,
        endDate,
        sort: 'time',
        direction: 'ASC',
        pageIndex: 1,
        pageSize: 10000,
      });

      return list
        .map((v) => ({
          ...v,
          finishPlayRatio: parseFloat(v.finishPlayRatio) || 0,
        }))
        .reduce((obj, cur) => {
          const key = cur['time'] as string;
          const datas = (obj[key] = obj[key] || []);
          datas.push(cur);
          return obj;
        }, {});
    },
    { refreshDeps: [id, JSON.stringify(rest)] },
  );
  return <ChartCore option={getOption(Object.values(data), Object.keys(data))} notMerge style={{ height: 280 }} />;
};

export default LineChart;
