import { dict } from '@/hooks/useChangeLocale';
import { getHotspotUpdateTime } from '@/services/hotspot';
import { HotRankTypeEnum } from '@/services/hotspot/type';
import { DateUtils } from '@/utils/dateUtil';
import { Alert } from '@antd';
import { useRequest } from 'ahooks';

export const UpdateTimeAlert = ({ rankType }: { rankType: HotRankTypeEnum }) => {
  const { data: syncTime = '' } = useRequest(() => getHotspotUpdateTime(rankType), {
    refreshDeps: [rankType],
  });
  return (
    <Alert
      message={dict('DATA_UPDATE_TIME_TIP', { syncTime: `${DateUtils.formatDateTime(syncTime)}` })}
      style={{ marginBottom: 10 }}
    />
  );
};
