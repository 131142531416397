import RecentDateBtnPick, { dateRangePickValues } from '@/components/Form/RecentDateBtnPick';
import { Divider, Space, Typography } from '@antd';
import { useSafeState } from 'ahooks';
import { DetailQueryFilter } from '../../../detail/$id';
import LineChart from './LineChart';
import List from './List';
import ProdDataIndex from './ProdDetailIndex';
import { dict } from '@/hooks/useChangeLocale';

type DataProps = {
  id: string;
};

export default ({ id }: DataProps) => {
  const [filter, setFilter] = useSafeState<DetailQueryFilter>({ id, rangeDate: dateRangePickValues[0][0] });

  return (
    <>
      <ProdDataIndex filter={filter} />
      <Divider style={{ marginTop: 0 }} />
      <Space>
        <Typography>{dict('AFTER_THE_VIDEO_IS_RELEASED')}</Typography>
        <RecentDateBtnPick
          value={filter.rangeDate}
          hasBorder
          showTotal={false}
          onChange={(rangeDate) => setFilter({ ...filter, rangeDate })}
        />
      </Space>

      <LineChart filter={filter} />
      <List filter={filter} />
    </>
  );
};
