import Text, { ArrLabelText, DateTimeText } from '@/components/Form/Field/Text';
import { Blank, FormCard } from '@/components/PageCard';
import { datePickerFormItemConfig, defaultFormItemConfig } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { postPublishItemData, putPublishItemData } from '@/services/item/publish';
import { App, Button, DatePicker, Flex, Form, Input, Radio } from '@antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React from 'react';
import {
  PUBLISH_CONTENT_TYPE,
  PUBLISH_STATUS,
  PublishContentOptions,
  PublishTypeOptions,
  VIDEO_PUBLISTH_TYPE,
  handleSubmitData,
} from '../../const';
import { PictureCoverUpload, PictureFileUpload, VideoFileUpload } from './fields/Upload';
import { useEventEmitter, useRequest } from 'ahooks';
import { Validator } from '@/utils/validator';
import { dict } from '@/hooks/useChangeLocale';
import { CheckCard } from '@ant-design/pro-components';
import { PictureOutlined, VideoCameraOutlined } from '@ant-design/icons';
import style from './style.less';
import { PlatformConfig } from './fields/PlatformConfig';

type FormContentProps = {
  opType: FormOpType;
  initialData: AnyObject;
};

const back = () => history.back();
const FormContent: React.FC<FormContentProps> = ({ opType, initialData }) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const isViewType = opType == FormOpType.VIEW;
  const validateFieldEvent$ = useEventEmitter<string>();

  const publishType = Form.useWatch('publishType', form);
  const contentType = Form.useWatch('contentType', form);

  //表单提交
  const { run: submitAction, loading: submitting } = useRequest(
    async (publishStatus: PUBLISH_STATUS) => {
      try {
        const datas = await form.validateFields();
        const submitDatas = handleSubmitData(datas);
        if (isEmpty(submitDatas.mediaId)) {
          return message.error('PLATFORM_NOT_CONFIGED_TIP');
        }
        const submitAction = datas.id ? putPublishItemData : postPublishItemData;
        await submitAction({ ...submitDatas, publishStatus });
        message.success(dict('OPERATION_SUCCESS'));
        back();
      } catch (error: ErrorCatch) {
        if (!isEmpty(error.errorFields)) {
          const [{ name }] = error.errorFields;
          validateFieldEvent$.emit(name[0]);
          form.scrollToField(name);
        } else if (error?.status?.message) {
          message.error(error?.status.message);
        }
      }
    },
    { manual: true },
  );

  const formBodyStyle = { flex: 1, background: '#fff' };

  return (
    <Form
      form={form}
      {...defaultFormItemConfig}
      labelCol={{ span: isViewType ? 8 : 6 }}
      wrapperCol={{ span: isViewType ? 6 : 12 }}
      initialValues={initialData}
      requiredMark={!isViewType}
      onSubmitCapture={async () => {
        try {
          const datas = await form.validateFields();
          const submitDatas = handleSubmitData(datas);
          const submitAction = datas.id ? putPublishItemData : postPublishItemData;
          await submitAction(submitDatas);
          message.success(dict('OPERATION_SUCCESS'));
          back();
        } catch (error: ErrorCatch) {
          if (error?.status?.message) {
            message.error(error?.status.message);
          }
        }
      }}
    >
      <Flex gap={1} style={{ overflow: 'hidden' }}>
        <div style={formBodyStyle}>
          <FormCard title={dict('PUBLISH_TYPE')} headerBordered={false}>
            <Form.Item label={dict('CONTENT_TYPE')} name="contentType" required rules={[{ required: true }]}>
              {isViewType ? (
                <ArrLabelText list={PublishContentOptions} />
              ) : (
                <CheckCard.Group
                  disabled={isViewType}
                  className={style['check-card']}
                  size="small"
                  onChange={() => form?.setFieldValue('filePath', undefined)}
                >
                  <CheckCard
                    avatar={<VideoCameraOutlined />}
                    title={dict('VIDEO')}
                    value={PUBLISH_CONTENT_TYPE.VIDEO}
                  />
                  <CheckCard
                    avatar={<PictureOutlined />}
                    title={dict('PICTURE_NOTE')}
                    value={PUBLISH_CONTENT_TYPE.PICTURE_TEXT}
                  />
                </CheckCard.Group>
              )}
            </Form.Item>
          </FormCard>

          <FormCard title={dict('UPLOAD_VIDEO')} headerBordered={false}>
            {contentType == PUBLISH_CONTENT_TYPE.VIDEO ? (
              <Form.Item
                label={dict('PUBLISH_VIDEO')}
                name="filePath"
                required
                rules={[
                  {
                    required: true,
                    validator(_, value) {
                      if (Validator.isNilEmpty(value)) return Promise.reject(dict('PLEASE_UPLOAD_VIDEO'));
                      return Promise.resolve();
                    },
                  },
                ]}
                tooltip={dict('UPLOAD_VIDEO_TIP')}
              >
                <VideoFileUpload readonly={isViewType} />
              </Form.Item>
            ) : null}

            {contentType == PUBLISH_CONTENT_TYPE.PICTURE_TEXT ? (
              <Form.Item
                label={dict('UPLOAD_PIC_TEXT')}
                name="filePath"
                required
                rules={[
                  {
                    required: true,
                    validator(_, value) {
                      if (Validator.isNilEmpty(value)) return Promise.reject(dict('PLEASE_UPLOAD_PIC_TEXT'));
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <PictureFileUpload readonly={isViewType} />
              </Form.Item>
            ) : null}
          </FormCard>

          <FormCard title={dict('GENERAL_CONFIG')} headerBordered={false}>
            <Form.Item hidden name="id" />
            <Form.Item hidden name="bid" />
            <Form.Item hidden name="tenantId" />

            <Form.Item label={dict('TASK_NAME')} name="name" required rules={[{ required: true, max: 50 }]}>
              {isViewType ? <Text /> : <Input placeholder={dict('PLEASE_TYPE')} />}
            </Form.Item>

            <Form.Item label={dict('PUBLISH_TIME')} required>
              <Flex style={{ marginBottom: -20 }}>
                <Form.Item
                  name="publishType"
                  hidden={isViewType && publishType == VIDEO_PUBLISTH_TYPE.SCHEDULE}
                  rules={[{ required: true }]}
                >
                  {isViewType ? (
                    <ArrLabelText list={PublishTypeOptions} />
                  ) : (
                    <Radio.Group options={PublishTypeOptions} />
                  )}
                </Form.Item>

                {/* 定时发送时需要设置发布时间 */}
                {publishType == VIDEO_PUBLISTH_TYPE.SCHEDULE && (
                  <Form.Item
                    name="publishTime"
                    rules={[
                      {
                        required: true,
                        message: dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('PUBLISH_TIME') }),
                      },
                      {
                        validator(_, value) {
                          if (value && dayjs().isAfter(value)) return Promise.reject(dict('PUBLISH_TIME_REQUIRED_TIP'));
                          return Promise.resolve();
                        },
                      },
                    ]}
                    {...datePickerFormItemConfig}
                  >
                    {isViewType ? (
                      <DateTimeText />
                    ) : (
                      <DatePicker showTime disabledDate={(curDate) => dayjs().isAfter(curDate)} />
                    )}
                  </Form.Item>
                )}
              </Flex>
            </Form.Item>

            <Form.Item label={dict('PUBLISH_COVER_IMG')} name="coverPath">
              <PictureCoverUpload readonly={isViewType} />
            </Form.Item>

            <Form.Item label={dict('REMARK')} name="remark" rules={[{ max: 255 }]}>
              {isViewType ? <Text /> : <Input.TextArea placeholder={dict('PLEASE_TYPE')} />}
            </Form.Item>
          </FormCard>
        </div>
        {/* <Divider /> */}
        <div style={formBodyStyle}>
          <PlatformConfig opType={opType} validateFieldEvent$={validateFieldEvent$} />
          <Form.Item name="mediaId" hidden />
        </div>
      </Flex>

      {!isViewType && (
        <>
          <Blank />
          <FormCard>
            <Flex justify="center" gap={10}>
              <Button onClick={back}>{dict('ACTION_CANCEL')}</Button>
              <Button type="primary" loading={submitting} onClick={() => submitAction(PUBLISH_STATUS.DRAFT)}>
                {dict('ACTION_SAVA_DRAFT')}
              </Button>

              <Button type="primary" loading={submitting} onClick={() => submitAction(PUBLISH_STATUS.PENDING)}>
                {dict('ACTION_SUBMIT_PUBLISH')}
              </Button>
            </Flex>
          </FormCard>
        </>
      )}
    </Form>
  );
};

export default FormContent;
