import Text, { ArrLabelText } from '@/components/Form/Field/Text';
import PoiSetInput from '@/components/Form/Field/poi/RegionPoiModal';
import { OrgAccountTreeSelect } from '@/components/Form/Field/select/OrgAccountTreeSelect';
import { Blank } from '@/components/PageCard';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { dict } from '@/hooks/useChangeLocale';
import { KsPublishViewPermissionOptions, columnConfig } from '@/pages/matrix-operation/publish/const';
import { Form, Input, Radio, Switch, TreeSelect } from '@antd';

type PlatformConfigContentProps = {
  opType: FormOpType;
};

export const KuaishouConfigContent = ({ opType }: PlatformConfigContentProps) => {
  const readonly = opType == FormOpType.VIEW;
  const form = Form.useFormInstance();
  const plartformName = 'ksPublishReq';
  const accountId = Form.useWatch([plartformName, 'accountId'], form);
  const showPoi = Form.useWatch([plartformName, '_showpoi'], form);

  return (
    <>
      <Form.Item
        {...columnConfig}
        label={dict('PUBLISH_ACCOUNT')}
        name={[plartformName, 'accountId']}
        required
        rules={[{ required: true }]}
      >
        {readonly ? (
          dict('SEND_ACCOUNT_NUM_TIP', { num: accountId?.length })
        ) : (
          <OrgAccountTreeSelect
            multiple
            treeDefaultExpandAll
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeCheckable
            filterMediaIds={MEDIA_KEY_ENUM.KUAISHOU}
          />
        )}
      </Form.Item>
      <Form.Item
        {...columnConfig}
        label={dict('WORK_INTRODUCTION')}
        name={[plartformName, 'caption']}
        required
        rules={[{ required: true, max: 500 }]}
      >
        {readonly ? <Text /> : <Input.TextArea placeholder={dict('PLEASE_TYPE')} />}
      </Form.Item>

      <Form.Item {...columnConfig} label={dict('POI_POSITION')} tooltip={dict('POSITON_SETTING_TIP')}>
        <Form.Item name={[plartformName, '_showpoi']} valuePropName="checked" noStyle>
          <Switch disabled={readonly} />
        </Form.Item>

        {showPoi && (
          <>
            <Blank />
            <Form.Item name={[plartformName, '_poi']} noStyle>
              <PoiSetInput
                placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('POI_POSITION') })}
                readOnly={readonly}
                mediaId={MEDIA_KEY_ENUM.KUAISHOU}
              />
            </Form.Item>
          </>
        )}
      </Form.Item>

      <Form.Item
        {...columnConfig}
        required
        label={dict('PERMISSION_SETTING')}
        initialValue={opType == FormOpType.CREATE ? KsPublishViewPermissionOptions[0].value : undefined}
        name={[plartformName, 'viewPermission']}
      >
        {readonly ? (
          <ArrLabelText list={KsPublishViewPermissionOptions} />
        ) : (
          <Radio.Group options={KsPublishViewPermissionOptions} />
        )}
      </Form.Item>
    </>
  );
};
