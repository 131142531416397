import { MEDIA_KEY_ENUM } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { Form, Image, Switch, Tabs } from '@antd';
import { without } from 'lodash';
import React, { useState } from 'react';
import { dict } from '@/hooks/useChangeLocale';
import { useAppContext } from '@/contexts/AppContext';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { DouyinConfigContent } from './douyin/Platform';
import { KuaishouConfigContent } from './kuaishou/Platform';
import { WeixinConfigContent } from './weixin/Platform';
import { XiaoHongShuConfigContent } from './xiaohongshu/Platform';
import { columnConfig } from '../../../const';
import douyinIcon from '@/assets/platforms/douyin.ico';
import kuaishouIcon from '@/assets/platforms/kuaishou.ico';
import shipinghaoIcon from '@/assets/platforms/shipinghao.ico';
import xiaohongshuIcon from '@/assets/platforms/xiaohongshu.ico';

export const PlatformConfig = ({
  opType,
  validateFieldEvent$,
}: {
  opType: FormOpType;
  validateFieldEvent$?: EventEmitter<string>;
}) => {
  const { platforms } = useAppContext();
  const supportPlatforms = platforms.map((v) => `${v.id}`);
  const form = Form.useFormInstance();
  const mediaIds: number[] = Form.useWatch('mediaId', form);
  const readonly = opType == FormOpType.VIEW;
  const [curPlatformKey, setPlatformKey] = useState(readonly ? mediaIds?.[0] : supportPlatforms[0]);

  const enableConfigPlatform = (mediaKey: MEDIA_KEY_ENUM) => (WrappedComponent) => {
    return (
      <>
        {readonly && <div style={{ marginTop: 15 }} />}
        {mediaIds?.includes(mediaKey) ? (
          WrappedComponent
        ) : (
          <Form.Item {...columnConfig} label={dict('PLATFORM_CONFIG')}>
            {dict('UN_OPENED')}
          </Form.Item>
        )}
      </>
    );
  };

  validateFieldEvent$?.useSubscription((field) => {
    const mapping = {
      douYinTaskDetailRequest: MEDIA_KEY_ENUM.DOUYIN,
      ksPublishReq: MEDIA_KEY_ENUM.KUAISHOU,
      xhsDetailPublishVO: MEDIA_KEY_ENUM.WEIXIN,
      wxDetailPublishVO: MEDIA_KEY_ENUM.XIAOHONGSHU,
    };

    if (mapping[field]) {
      setPlatformKey(`${mapping[field]}`);
    }
  });

  const getIcon = (url) => {
    return <Image width={18} preview={false} src={url} />;
  };

  return (
    <Tabs
      type="card"
      activeKey={curPlatformKey}
      onChange={setPlatformKey}
      tabBarExtraContent={
        readonly ? undefined : (
          <Switch
            checked={mediaIds?.includes(Number(curPlatformKey))}
            onChange={(checked) => {
              const key = Number(curPlatformKey);
              form?.setFieldValue('mediaId', checked ? [...mediaIds, key] : without(mediaIds, key));
            }}
          />
        )
      }
      style={readonly ? {} : { margin: 5 }}
      items={[
        {
          icon: getIcon(douyinIcon),
          label: dict('PLATFORM_DOUYIN'),
          key: `${MEDIA_KEY_ENUM.DOUYIN}`,
          children: enableConfigPlatform(MEDIA_KEY_ENUM.DOUYIN)(<DouyinConfigContent opType={opType} />),
        },
        {
          icon: getIcon(kuaishouIcon),
          label: dict('PLATFORM_KUAISHOU'),
          key: `${MEDIA_KEY_ENUM.KUAISHOU}`,
          children: enableConfigPlatform(MEDIA_KEY_ENUM.KUAISHOU)(<KuaishouConfigContent opType={opType} />),
        },
        {
          icon: getIcon(shipinghaoIcon),
          label: dict('PLATFORM_SHIPINGHAO'),
          key: `${MEDIA_KEY_ENUM.WEIXIN}`,
          children: enableConfigPlatform(MEDIA_KEY_ENUM.WEIXIN)(<WeixinConfigContent opType={opType} />),
        },
        {
          icon: getIcon(xiaohongshuIcon),
          label: dict('PLATFORM_XIHONGSHU'),
          key: `${MEDIA_KEY_ENUM.XIAOHONGSHU}`,
          children: enableConfigPlatform(MEDIA_KEY_ENUM.XIAOHONGSHU)(<XiaoHongShuConfigContent opType={opType} />),
        },
      ].filter((v) => (readonly ? mediaIds?.includes(Number(v.key)) : supportPlatforms.includes(v.key)))}
    />
  );
};
