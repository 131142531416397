import { AccountAvator } from '@/components/AccountAvator';
import { DateUtils } from '@/utils/dateUtil';
import { Card, Descriptions, Typography } from '@antd';
import { MEDIA_KEY_ENUM, getMediaName } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';

export const VideoProductionPageHeader = ({ detail }: { detail: AnyObject }) => {
  const { regionName, distributorName, storeName, staffName, influenceName } = detail || {};
  const orgFullName = [regionName, distributorName, storeName, staffName, influenceName].filter(Boolean).join('-');
  const { getBtnPermissionConfig } = usePermission();

  return (
    <Card loading={!detail}>
      <Descriptions title={detail?.title} column={3}>
        <Descriptions.Item label={dict('PUBLISH_ACCOUNT')}>
          <AccountAvator avaterUrl={detail?.avatar} name={detail?.accountName} style={{ marginTop: -5 }} />
        </Descriptions.Item>
        <Descriptions.Item label={dict('PLATFORM')}>
          {getMediaName((detail?.mediaId as MEDIA_KEY_ENUM) || MEDIA_KEY_ENUM.DOUYIN)}
        </Descriptions.Item>
        <Descriptions.Item label={dict('AFFILIATION_ORG')}>
          <Typography.Text ellipsis title={orgFullName} style={{ width: 200 }}>
            {orgFullName}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label={dict('PUBLISH_TIME')}>
          {DateUtils.formatDateTime(detail?.publishTime)}
        </Descriptions.Item>
        {[MEDIA_KEY_ENUM.DOUYIN, MEDIA_KEY_ENUM.WEIXIN].includes(detail?.mediaId as MEDIA_KEY_ENUM) &&
          !getBtnPermissionConfig(AUTH.TAG_MANAGEMENT.PAGE)?.disabled && (
            <Descriptions.Item label={dict('TAG')}>{detail?.tags?.join('，') || '-'}</Descriptions.Item>
          )}
      </Descriptions>
    </Card>
  );
};
