import { Empty, Typography } from '@antd';
import { isEmpty } from 'lodash';

export const Tags: React.FC<{ value?: string[] }> = ({ value }) => {
  return isEmpty(value) ? (
    <Empty style={{ padding: 55 }} />
  ) : (
    <div style={{ display: 'flex', gap: 5, padding: 20, flexWrap: 'wrap', maxHeight: 300, overflowY: 'auto' }}>
      {value?.map((v, index) => (
        <Typography.Paragraph
          key={index}
          title={v}
          style={{
            padding: '5px 10px',
            borderRadius: 5,
            backgroundColor: 'rgb(2,167,240)',
            maxWidth: '100%',
            border: 0,
            color: 'white',
          }}
          ellipsis={{ rows: 10 }}
        >
          {v}
        </Typography.Paragraph>
      ))}
    </div>
  );
};
