import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import { getReportTotal } from '@/services/item';
import { Format } from '@/utils/format';
import { useRequest } from 'ahooks';
import { VIDEO_NOTE_TYPE } from '@/pages/matrix-operation/production/const';
import { DetailQueryFilter } from '../../../detail/$id';
import { dict } from '@/hooks/useChangeLocale';

type ProdDetailIndexProps = {
  filter: DetailQueryFilter;
  detail: AnyObject;
};
const ProdDetailIndex = ({ filter, detail }: ProdDetailIndexProps) => {
  const { id } = filter;
  const { data: item, loading } = useRequest(
    () =>
      getReportTotal({
        itemId: id,
        fromNow: true,
      }),
    { refreshDeps: [id] },
  );

  const { noteType } = detail || {};
  const isVideo = noteType == VIDEO_NOTE_TYPE.VIEDO;
  const isNormal = noteType == VIDEO_NOTE_TYPE.NORMAL;
  const isAll = !noteType;

  const items: StatisticItem[] = [
    {
      title: isAll ? dict('NOTE_COUNT_TOTAL') : isVideo ? dict('VIDEO_COUNT_TOTAL') : dict('IMAGE_NOTE_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.total),
      suffix: dict('STATIST_UNIT_GE'),
    },
    {
      title: dict('VIEWS_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.playCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('COMMENT_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.commentCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('LIKED_TOTAL'),
      value: Format.toAmountNumber(item?.likeCount),
      suffix: dict('STATIST_UNIT_GE'),
    },
    {
      title: dict('SHARE_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.shareCount),
      suffix: dict('STATIST_UNIT_CI'),
    },

    {
      title: dict('COLLECTION_TOTAL'),
      value: Format.toAmountNumber(item?.fav),
      suffix: dict('STATIST_UNIT_CI'),
    },

    isNormal
      ? null
      : {
          title: dict('BULLET_CONTENT'),
          value: Format.toAmountNumber(item?.danmaku),
          suffix: dict('STATIST_UNIT_TIAO'),
        },

    isNormal
      ? null
      : {
          title: dict('AVG_WATCH_TIME'),
          value: Format.toAmountNumber((item?.avgPlayTimeSec || 0) / 60, 2),
          suffix: dict('STATIST_UNIT_MIN'),
        },

    {
      title: isAll ? dict('INCREASING_NOTES_TOTAL') : dict('INCREASING_FUNS_TOTAL'),
      value: Format.toAmountNumber(item?.followCount),
    },
  ].filter((v) => v) as StatisticItem[];

  return <StatisticCardPanel items={items} columns={items.length} loading={loading} />;
};

export default ProdDetailIndex;
