import { useAppContext } from '@/contexts/AppContext';
import { getReportCommentPage } from '@/services/item';
import { Card, Divider, Empty, Flex, List, Typography } from '@antd';
import { useInfiniteScroll } from 'ahooks';
import React, { useRef } from 'react';
import { CommentRecord } from './CommentList';
import { NotePlay, VideoPlay } from './VideoPlay';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { VIDEO_NOTE_TYPE } from '../../../const';
import { dict } from '@/hooks/useChangeLocale';

const { Text } = Typography;

export default ({ id, detail }: { id: string; detail }) => {
  const { user } = useAppContext();
  const mediaId = detail?.mediaId as MEDIA_KEY_ENUM;

  const ref = useRef<HTMLDivElement>(null);

  //加载更多数据
  const getLoadMoreList = async (curPageIndex: string | undefined, pageSize: number) => {
    const pageIndex = Number(curPageIndex) || 1;
    const { list, pageInfo } = await getReportCommentPage({
      itemId: id,
      pageIndex,
      pageSize,
      bid: user?.bid,
    });
    return {
      list,
      nextId: pageIndex * pageSize < pageInfo.total ? pageIndex + 1 : undefined,
    };
  };

  //滚动无限加载逻辑
  const { data, loading, noMore } = useInfiniteScroll((d) => getLoadMoreList(d?.nextId, 10), {
    target: ref,
    isNoMore: (d) => d?.nextId == undefined,
  });

  return (
    <Flex gap="small">
      {[MEDIA_KEY_ENUM.XIAOHONGSHU, MEDIA_KEY_ENUM.WEIXIN].includes(mediaId) &&
      detail?.noteType == VIDEO_NOTE_TYPE.NORMAL ? (
        <Card title={dict('PICTURE_NOTE')} size="small" style={{ width: 300 }}>
          <NotePlay mediaId={mediaId} id={id} />
        </Card>
      ) : (
        <Card title={dict('VIDEO')} size="small" style={{ width: 300 }}>
          <VideoPlay mediaId={mediaId} detail={detail} id={id} />
        </Card>
      )}
      <Card title={dict('COMMENT')} size="small" style={{ flex: 1 }}>
        <div style={{ maxHeight: 575, overflowY: 'auto' }} ref={ref}>
          <List loading={loading}>
            {data?.list?.map((record, index) => (
              <React.Fragment key={index}>
                <CommentRecord comment={record} level={1} />
                <Divider style={{ margin: '10px 0' }} />
              </React.Fragment>
            ))}
          </List>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {!data?.list.length ? <Empty /> : noMore && !loading ? <Text>{dict('NO_MORE_DATA')}</Text> : undefined}
          </div>
        </div>
      </Card>
    </Flex>
  );
};
