import { VideoCoverImg } from '@/components/VideoCoverImg';
import { FAULT_IMAGE, MEDIA_KEY_ENUM } from '@/constants/const';
import { getVideoData } from '@/services/video';
import { useRequest } from 'ahooks';
import { Card, Carousel, Image, Spin } from '@antd';
import React, { useEffect, useRef } from 'react';
import Player from 'xgplayer';
// import HlsPlayer from 'xgplayer-hls.js';
// import HlsPlayer from 'xgplayer-hls';
import 'xgplayer/dist/index.min.css';
import { getXhsNoteDetail } from '@/services/item/xhs';
import { EmojiImgText } from '@/components/EmojiImg';
import { dict } from '@/hooks/useChangeLocale';
import { isEmpty } from 'lodash';
import { getWxNoteDetail } from '@/services/item/wx';

type VideoPlayProps = {
  url: string;
  coverUrl?: string;
};

const LocalVideoPlay: React.FC<VideoPlayProps> = ({ url, coverUrl }) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player>();

  //字幕视频逻辑
  useEffect(() => {
    const player = new Player({
      el: videoContainerRef.current as HTMLDivElement,
      autoplay: false,
      playbackRate: [0.5, 0.75, 1, 1.5, 2, 5, 10],
      url,
      width: '100%',
      height: 300,
      isLive: false,
      // fluid: true, // 流体
      cssFullscreen: false,
      poster: coverUrl,
      fitVideoSize: 'fixWidth',
      // plugins: [HlsPlayer],
    });

    playerRef.current = player;

    return () => {
      player.offAll();
    };
  }, [url, coverUrl]);

  return (
    <>
      <div ref={videoContainerRef} />
    </>
  );
};

export const VideoPlay: React.FC<{ id; detail: AnyObject; mediaId: MEDIA_KEY_ENUM }> = ({ mediaId, id, detail }) => {
  //加载视频地址
  const { data: videoData, loading } = useRequest(
    async () => {
      try {
        const { list } = await getVideoData(id);
        return list[0];
      } catch (error) {}
    },
    { refreshDeps: [id] },
  );

  const coverUrl = detail?.coverOssUrl || detail?.cover;
  const url = videoData?.share_oss_url || (videoData?.share_url as string);

  return (
    <Spin spinning={loading}>
      {[MEDIA_KEY_ENUM.DOUYIN, MEDIA_KEY_ENUM.KUAISHOU].includes(mediaId) ? (
        <VideoCoverImg style={{ width: 278 }} platformPlayUrl={url} src={coverUrl} />
      ) : (
        <LocalVideoPlay url={url} coverUrl={coverUrl} />
      )}
    </Spin>
  );
};

const mediaNoteDetailActioMapping = {
  [MEDIA_KEY_ENUM.XIAOHONGSHU]: getXhsNoteDetail,
  [MEDIA_KEY_ENUM.WEIXIN]: getWxNoteDetail,
};
export const NotePlay: React.FC<{ id; mediaId }> = ({ id, mediaId }) => {
  //加载视频地址
  const { data, loading } = useRequest(
    async () => {
      const getMediaData = mediaNoteDetailActioMapping[mediaId];
      const data = await getMediaData(id);
      if (!isEmpty(data.imageOssUrl)) {
        data.imageUrl = data.imageOssUrl;
      }
      return data;
    },
    { refreshDeps: [id, mediaId] },
  );

  return (
    <Spin spinning={loading}>
      <Carousel dotPosition="top" autoplay>
        {data?.imageUrl?.map((url, index) => {
          return data?.content ? (
            <Card
              key={index}
              cover={<Image alt={dict('PICTURE')} src={url} fallback={FAULT_IMAGE} referrerPolicy="no-referrer" />}
            >
              <Card.Meta description={<EmojiImgText content={data?.content} />} />
            </Card>
          ) : (
            <Image alt={dict('PICTURE')} src={url} fallback={FAULT_IMAGE} referrerPolicy="no-referrer" />
          );
        })}
      </Carousel>
    </Spin>
  );
};
