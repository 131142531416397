import { FAULT_IMAGE } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';
import { CancelCollect } from '@/icons';
import { Avatar, Typography } from '@antd';
import { useBoolean } from 'ahooks';
import classNames from 'classnames';
import { CSSProperties } from 'react';

type VideoCardProps = {
  cover: string;
  style?: CSSProperties | undefined;
  title: string;
  avatar?: string;
  nickName?: string;
  date?: string;
  viewable?: boolean;
  deletable?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
};

export const VideoCard = (props: VideoCardProps) => {
  const { cover, title, nickName, date, avatar, onClick, onDelete, deletable, viewable } = props;
  const [hover, { setTrue: setHover, setFalse: cancelHover }] = useBoolean();

  return (
    <div style={{ width: 200 }}>
      <div
        className={classNames('relative', { 'cursor-pointer': viewable })}
        title={viewable ? dict('CLICK_VIEW_MORE') : ''}
        onMouseEnter={setHover}
        onMouseLeave={cancelHover}
        onClick={viewable ? onClick : undefined}
      >
        {deletable && hover && (
          <div
            title={viewable ? dict('CLICK_CANCEL_COLLECT') : ''}
            className=" absolute cursor-pointer  rounded-sm  flex justify-center align-middle w-8 h-8 top-2 right-2"
          >
            <CancelCollect
              className="text-white  hover:scale-110"
              style={{ fontSize: 20 }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete?.();
              }}
            />
          </div>
        )}

        <img
          src={cover || FAULT_IMAGE}
          style={{ width: 200, minHeight: 300, borderRadius: 10, display: 'block' }}
          referrerPolicy="same-origin"
        />
      </div>

      <Typography.Paragraph
        title={title}
        style={{ fontWeight: 'bold', fontSize: 14, color: '#333', padding: 0, marginBottom: 0, marginTop: 5 }}
        ellipsis={{ rows: 1 }}
      >
        {title || '-'}
      </Typography.Paragraph>
      {avatar && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8 }}>
          <div>
            <Avatar size="small" src={avatar} />
            <span style={{ fontSize: 12, fontSizeAdjust: 1.5, marginLeft: 10 }}>{nickName}</span>
          </div>

          {date && <text style={{ fontSize: 12 }}>{date}</text>}
        </div>
      )}
    </div>
  );
};
