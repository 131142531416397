import { AccountAvator } from '@/components/AccountAvator';
import { EmojiImgText } from '@/components/EmojiImg';
import { useAppContext } from '@/contexts/AppContext';
import useTableRequest, { getColumnSearchProps, getTableColumn } from '@/hooks/useTableRequest';
import { UsageScopeEnum, getKeywords } from '@/pages/system/keyword/const.var';
import { getCommentPage, getCommentTotal } from '@/services/comment';
import { getKeywordCategoryList } from '@/services/keyword_category';
import type { QueryKeywordCategoryItem } from '@/services/keyword_category/type';
import { DATE_MINI_FORMAT_STR, DateUtils } from '@/utils/dateUtil';
import { Format } from '@/utils/format';
import { Validator } from '@/utils/validator';
import { Menu, Table, type MenuProps } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { get, isEmpty, isUndefined, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getSoretParams } from '../../../const';
import { dict } from '@/hooks/useChangeLocale';

type MenuItem = Required<MenuProps>['items'][number];
export const Unclassifyied = 'unclassifyied';
function getItem(label: React.ReactNode, key: React.Key, isAll: boolean, icon?: React.ReactNode): MenuItem {
  return {
    key,
    icon,
    label,
    style: {
      paddingLeft: isAll ? undefined : 30,
      width: 200,
    },
  } as MenuItem;
}
export const LeftNav = ({ event$, id }: { event$: EventEmitter<EmitterObject>; id: Key }) => {
  const { user } = useAppContext();

  const [categoryIds, setCaterogyIds] = useState<string>();
  const [queryAll, setQueryAll] = useState(false);

  //加载关键词分类
  const { data: keywordCategoryList } = useRequest(
    async () => {
      const keywordCategoryList = await getKeywordCategoryList({
        bid: user?.bid,
        type: UsageScopeEnum.Comment,
        disabled: 0, //有效的
      });
      event$.emit({ action: 'SET_CATEGORY_LIST', value: keywordCategoryList });
      setCaterogyIds(keywordCategoryList.map((item) => item.id).join(',')); //设置默认关键词
      setQueryAll(true);
      return keywordCategoryList;
    },
    {
      refreshDeps: [user?.bid],
    },
  );

  //加载统计数据
  const { data: keywordCategoryListCountMap } = useRequest(
    async () => {
      if (!keywordCategoryList) return undefined;
      const { sumCount, categoryTotal, unclassifiedCount } = await getCommentTotal({
        categoryIds: (keywordCategoryList ?? []).map((v) => v.id).join(','),
        itemId: id as string,
        bid: user?.bid,
      });
      const countMap = categoryTotal.reduce((map, cur) => {
        map[cur.categoryId] = cur.count;
        return map;
      }, {});
      return {
        all: sumCount,
        notMatch: unclassifiedCount,
        ...countMap,
      };
    },
    { refreshDeps: [keywordCategoryList, id, user?.bid] },
  );

  const getNavItemTitle = (title, count) => {
    if (count)
      return (
        <div title={dict('COMMENT_COUNT_TIP', { count })}>
          {title}（{Format.toFirendlyNumText(count)}）
        </div>
      );

    return <div title={dict('NO_COMMENT')}>{title}</div>;
  };

  const items: MenuProps['items'] = useCreation(() => {
    return [
      getItem(
        getNavItemTitle(dict('ALL'), get(keywordCategoryListCountMap, 'all')),
        `${keywordCategoryList?.map((item) => item.id).join(',')}` + `_${true}`,
        true,
      ),
      ...(keywordCategoryList || []).map((v) =>
        getItem(getNavItemTitle(v.name, get(keywordCategoryListCountMap, v.id)), `${v.id}_${false}`, false),
      ),
      getItem(
        getNavItemTitle(dict('UNCLASSIFIED'), get(keywordCategoryListCountMap, 'notMatch')),
        `${Unclassifyied}_${false}`,
        false,
      ),
    ];
  }, [keywordCategoryList, keywordCategoryListCountMap]);

  //通知列表更新
  useEffect(() => {
    if (!isUndefined(categoryIds)) {
      event$.emit({ action: 'REFRESH_TABLE', value: { queryAll, categoryIds } });
    }
  }, [categoryIds, queryAll]);

  return (
    <Menu
      className="page-left-nav"
      selectedKeys={[`${categoryIds}_${queryAll}`]}
      onClick={(e) => {
        const [categoryIds, queryAll] = e.key.split('_');
        setCaterogyIds(categoryIds);
        setQueryAll(queryAll == 'true');
      }}
      items={items}
    />
  );
};

type ListPros = {
  id: Key;
  event$: EventEmitter<EmitterObject>;
};
export const List = ({ id, event$ }: ListPros) => {
  const { user } = useAppContext();
  const [keyword, setKeyword] = useState('');
  const [keywordCategoryList, setKeywordCategroyList] = useState<QueryKeywordCategoryItem[]>([]);
  const [params, setParams] = useState({ queryAll: false, categoryIds: undefined });
  const [filter, setFilter] = useState<RequestSearchParams>({});

  //列表数据加载
  const { tableProps, params: tableParams } = useTableRequest(
    async ({ pageIndex, pageSize, filters = {}, sorter }) => {
      const { nickName, content, title, categoryList } = filters;
      const keyword = get(content, '[0]');
      const { categoryIds, queryAll } = params;
      if (isUndefined(categoryIds)) return { list: [] };
      const hasNoFilterCategory = isEmpty(categoryList);
      const queryUnclassified = categoryIds == Unclassifyied;
      const ids = queryAll
        ? hasNoFilterCategory
          ? categoryIds
          : categoryList?.join(',')
        : queryUnclassified
          ? keywordCategoryList.map((v) => v.id).join(',') //未分类
          : categoryIds; //指定目录
      const queryParams = {
        ...getSoretParams(sorter),
        queryAll: queryAll && hasNoFilterCategory,
        itemId: id,
        keyword,
        queryUnclassified,
        nickName: get(nickName, '[0]'),
        title: get(title, '[0]'),
        bid: user?.bid,
        categoryIds: ids,
      };
      setKeyword(keyword);
      setFilter(filter);
      return getCommentPage({
        ...queryParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params, user, keywordCategoryList], debounceWait: 100 },
    false,
  );

  //更新左侧事件响应
  event$.useSubscription(({ action, value }) => {
    if (action == 'SET_CATEGORY_LIST') setKeywordCategroyList(value);
    if (action == 'REFRESH_TABLE') {
      setParams(value);
      set(tableParams, '[0].filters', {});
    }
  });

  const TableList = useCreation(() => {
    return (props) => <Table {...props} />;
  }, [params]);

  return (
    <TableList
      {...tableProps}
      scroll={{ y: 580 }}
      size="small"
      columns={
        [
          getTableColumn({
            title: dict('COMMENT_TIME_ID'),
            dataIndex: 'createTime',
            sorter: true,
            align: 'left',
            render: (v, record) => {
              return (
                <>
                  <div>{DateUtils.format(v, DATE_MINI_FORMAT_STR)}</div>
                  <div style={{ color: '#999', marginTop: 3 }}>{record.id}</div>
                </>
              );
            },
          }),
          getTableColumn({
            title: dict('NICKNAME'),
            dataIndex: 'nickName',
            ...getColumnSearchProps({
              dataIndex: 'nickName',
            }),
            render: (_, record) => <AccountAvator avaterUrl={record?.avatar} name={record?.nickName} />,
          }),

          getTableColumn({
            title: dict('COMMENT_CONTENT'),
            dataIndex: 'content',
            align: 'left',
            width: 150,
            ...getColumnSearchProps({
              dataIndex: 'content',
            }),
            render: (v, record) => (
              <div style={{ maxHeight: 150, overflowY: 'auto' }}>
                <EmojiImgText content={v} searchWord={keyword} keywords={getKeywords(record.keywordMap)} />
              </div>
            ),
          }),

          params.queryAll
            ? getTableColumn({
                title: dict('CLASSIFICATION'),
                dataIndex: 'categoryList',
                ellipsis: true,
                filters: keywordCategoryList.map(({ id, name }) => ({ text: name, value: id })),
                filteredValue: filter.categoryList,
                render: (categoryNames) => {
                  return Validator.isNilEmpty(categoryNames) ? '-' : categoryNames.join(',');
                },
              })
            : null,

          params.categoryIds == Unclassifyied
            ? null
            : getTableColumn({
                title: dict('KEYWORD'),
                dataIndex: 'keywordList',
                ellipsis: true,
                render: (keywordList) => {
                  return Validator.isNilEmpty(keywordList) ? '-' : keywordList.join(',');
                },
              }),
        ].filter((v) => v) as TableCoumn[]
      }
    />
  );
};
