import { FormCard } from '@/components/PageCard';
import { Flex, Form } from '@antd';
import React from 'react';
import { PUBLISH_CONTENT_TYPE } from '../../const';
import { PictureCoverUpload, PictureFileUpload, VideoFileUpload } from './fields/Upload';
import { dict } from '@/hooks/useChangeLocale';
import { PlatformConfig } from './fields/PlatformConfig';
import { FormOpType } from '@/constants/enum';
import { defaultFormItemConfig } from '@/constants/const';

type FormContentProps = {
  initialData: AnyObject;
};

const FormContent: React.FC<FormContentProps> = ({ initialData }) => {
  const [form] = Form.useForm();

  const contentType = Form.useWatch('contentType', form);
  const coverPath = Form.useWatch('coverPath', form);
  const formBodyStyle = { flex: 1, background: '#fff' };

  return (
    <Form
      form={form}
      initialValues={initialData}
      {...defaultFormItemConfig}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      requiredMark={false}
    >
      <Flex gap={1}>
        <div style={formBodyStyle}>
          <FormCard title={dict('CONTENT_SETTING')} headerBordered={false}>
            <Form.Item hidden name="contentType" />

            {contentType == PUBLISH_CONTENT_TYPE.VIDEO ? (
              <Form.Item label={dict('PUBLISH_VIDEO')} name="filePath">
                <VideoFileUpload readonly={true} />
              </Form.Item>
            ) : null}

            {contentType == PUBLISH_CONTENT_TYPE.PICTURE_TEXT ? (
              <Form.Item label={dict('UPLOAD_PIC_TEXT')} name="filePath">
                <PictureFileUpload readonly={true} />
              </Form.Item>
            ) : null}

            {coverPath && (
              <Form.Item label={dict('PUBLISH_COVER_IMG')} name="coverPath">
                <PictureCoverUpload readonly={true} />
              </Form.Item>
            )}
          </FormCard>
        </div>

        <div style={formBodyStyle}>
          <PlatformConfig opType={FormOpType.VIEW} /> <Form.Item name="mediaId" hidden />
        </div>
      </Flex>
    </Form>
  );
};

export default FormContent;
