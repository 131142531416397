import { AccountAvator } from '@/components/AccountAvator';
import { FormCard } from '@/components/PageCard';
import { VideoCoverImg } from '@/components/VideoCoverImg';
import { FAULT_IMAGE, MEDIA_ID_MAPPING, MEDIA_KEY_ENUM, ROUTE_PATH } from '@/constants/const';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { downloadReportSummary, getReportSummary, updateItemTag } from '@/services/item';
import { DateUtils } from '@/utils/dateUtil';
import { App, Image, Space, Spin, Table, Typography } from '@antd';
import { useRequest, useSafeState } from 'ahooks';
import { VIDEO_NOTE_TYPE, convertDbFilterToFilter, getSoretParams } from '../../const';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';
import { QueryReportParams } from '@/services/item/type';
import { useAddTagModal } from '@/components/modal/AddTagModal';
import { useAppContext } from '@/contexts/AppContext';
import { useCollectMaterialModal } from '@/components/modal/useCollectCaseModal';
import { View } from '@/icons';
import { CommentOutlined, HeartOutlined, TagsOutlined } from '@ant-design/icons';
import { useTagColumns } from '@/hooks/tag/useTagColumn';

type ListPros = {
  params: RequestSearchParams;
  inTag?: boolean;
};

const { Link } = Typography;

const List = ({ params, inTag }: ListPros) => {
  const { user } = useAppContext();
  const { message } = App.useApp();
  const [downloadParams, setDownloadParams] = useSafeState<RequestSearchParams>({});

  const { refresh: download, loading: downloadLoading } = useRequest(
    () => downloadReportSummary(downloadParams as QueryReportParams),
    { manual: true },
  );

  const { run: updateTag, loading: updateTagLoading } = useRequest(
    async (itemId, tagIds) => {
      try {
        await updateItemTag(itemId, tagIds, user?.bid);
        message.success(dict('OPERATION_SUCCESS'));
        refresh();
      } catch (error) {
        message.error(dict('OPERATION_FAILURE'));
      }
    },
    {
      refreshDeps: [user],
      manual: true,
    },
  );

  const { tableProps, refresh } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'publishTime', order: 'descend' } }) => {
      const { publish_time, tagValueIds, ...rest } = params;
      const [startTime, endTime] = publish_time ?? [];
      const downloadParams = {
        startTime,
        endTime,
        tagValueIds: tagValueIds?.map((v) => v[1]),
        ...getSoretParams(sorter),
        ...convertDbFilterToFilter(rest),
      };
      setDownloadParams(downloadParams);
      return getReportSummary({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params], debounceWait: 100 },
  );

  const [AddTagModal, showTagModal] = useAddTagModal();
  const [CollectMaterialModal, collectLoading, showCollectMaterialModal] = useCollectMaterialModal();

  const ROUTE_PRODUCTION_PATH = `/${ROUTE_PATH.MATRIX}/${ROUTE_PATH.PRODUCTION}`;
  const { getBtnPermissionConfig } = usePermission();
  const viewAuthConfig = getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.VIEW);
  const addfavourAuthConfig = getBtnPermissionConfig(AUTH.MATERIAL_MANAGEMENT.CREATE);

  const hasTags = !getBtnPermissionConfig(AUTH.TAG_MANAGEMENT.PAGE)?.disabled;
  const [tagColumns] = useTagColumns();

  return (
    <FormCard
      title={dict('LIST_WORKS')}
      style={{ marginBottom: 10 }}
      extra={
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      }
    >
      <Table
        {...tableProps}
        loading={tableProps.loading || updateTagLoading || collectLoading}
        scroll={{ x: 1200, y: inTag ? undefined : 500 }}
        columns={
          [
            getTableColumn({
              title: dict('COVER'),
              dataIndex: 'cover',
              width: 100,
              render: (url, record) =>
                record.noteType == VIDEO_NOTE_TYPE.NORMAL ? (
                  <Image
                    preview={false}
                    style={{ minHeight: 80, borderRadius: 5 }}
                    fallback={FAULT_IMAGE}
                    referrerPolicy="same-origin"
                    src={record.coverOssUrl || url}
                  />
                ) : (
                  <VideoCoverImg
                    style={{ width: 80, borderRadius: 10 }}
                    itemId={record.itemId}
                    src={record.coverOssUrl || url}
                  />
                ),
            }),
            getTableColumn({
              title: dict('TITLE_OF_WORK'),
              dataIndex: 'title',
              width: 150,
              render: (value) => (
                <Typography.Paragraph ellipsis={{ rows: 2, tooltip: true }}>{value}</Typography.Paragraph>
              ),
            }),
            getTableColumn({
              title: dict('PUBLISH_ACCOUNT'),
              dataIndex: 'accountName',
              width: 150,
              render: (_, record) => <AccountAvator avaterUrl={record?.avatar} name={record?.accountName} />,
            }),
            getTableColumn({
              title: dict('REGION_AREA'),
              dataIndex: 'regionName',
            }),
            getTableColumn({
              title: dict('DEALER'),
              dataIndex: 'distributorName',
            }),
            getTableColumn({
              title: dict('DEALER_CODE'),
              dataIndex: 'distributorCode',
            }),
            // getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
            // getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
            // getTableColumn({
            //   title: dict('INFLUENCER'),
            //   dataIndex: 'influenceName',
            // }),
            // hasTags
            //   ? getTableColumn({
            //       title: dict('TAG'),
            //       dataIndex: 'tagInfoList',
            //       width: 200,
            //       ellipsis: true,
            //       render: (tagInfoList) =>
            //         isEmpty(tagInfoList) ? '-' : tagInfoList?.map((v) => `${v.tagName}-${v.tagValueName}`).join(','),
            //     })
            //   : null,
            ...(tagColumns as any[]),
            getTableColumn({
              title: dict('AFFILIATION_PLATFORM'),
              dataIndex: 'mediaName',
            }),
            getTableColumn({
              title: dict('PLAY_TOTAL'),
              dataIndex: 'playCount',
              sorter: true,
            }),
            getTableColumn({
              title: dict('LIKE_TOTAL'),
              dataIndex: 'likeCount',
              sorter: true,
            }),
            getTableColumn({
              title: dict('SHARE_TOTAL'),
              dataIndex: 'shareCount',
              sorter: true,
            }),
            getTableColumn({
              title: dict('COMMENT_TOTAL'),
              dataIndex: 'commentCount',
              sorter: true,
            }),
            getTableColumn({
              title: dict('PUBLISH_TIME'),
              dataIndex: 'publishTime',
              align: 'center',
              width: 120,
              sorter: true,
              defaultSortOrder: 'descend',
              render: (v) => DateUtils.formatDateTime(v),
            }),
            getTableColumn({
              title: dict('ACTION_OPERATE'),
              width: 120,
              align: 'center',
              fixed: 'right',
              render: (_, record) => {
                const { mediaId } = record;
                const url = `${ROUTE_PRODUCTION_PATH}/${
                  MEDIA_ID_MAPPING[mediaId]
                }/${ROUTE_PATH.DETAIL}/${encodeURIComponent(record.itemId)}`;
                const isKuaishou = mediaId === MEDIA_KEY_ENUM.KUAISHOU;
                const isXiaohongshu = mediaId === MEDIA_KEY_ENUM.XIAOHONGSHU;
                const isDouyin = mediaId === MEDIA_KEY_ENUM.DOUYIN;
                const unSupportAddTags = isKuaishou || isXiaohongshu;
                return (
                  <Space>
                    <Link
                      title={dict('ACTION_VIEW_INTERACTIVE')}
                      {...viewAuthConfig}
                      onClick={() => window.open(`${BASE_PATH}${url}?type=video-detail`)}
                    >
                      <View title={dict('ACTION_VIEW')} className="action-icon" />
                    </Link>
                    <Link
                      title={dict('ACTION_VIEW_COMMENT')}
                      {...viewAuthConfig}
                      onClick={() => window.open(`${BASE_PATH}${url}?type=comment`)}
                    >
                      <CommentOutlined title={dict('COMMENT')} />
                    </Link>

                    {hasTags && (
                      <Link
                        title={
                          unSupportAddTags
                            ? dict('NOT_CURRENTLY_SUPPORTED')
                            : dict('ACTION_ADD_NAME', { name: dict('TAG') })
                        }
                        disabled={unSupportAddTags}
                        onClick={() => {
                          showTagModal(
                            record?.tagInfoList?.filter((v) => v.type == 'manual').map((v) => [v.tagId, v.tagValId]),
                            record?.tagInfoList?.filter((v) => v.type == 'automatic').map((v) => [v.tagId, v.tagValId]),
                            record.itemId,
                          );
                        }}
                      >
                        <TagsOutlined />
                      </Link>
                    )}

                    <Link
                      title={
                        record.topCase
                          ? dict('JOIN_EXCELLENT_CASE_ALREADY')
                          : isDouyin
                            ? dict('JOIN_EXCELLENT_CASE')
                            : dict('NOT_CURRENTLY_SUPPORTED')
                      }
                      disabled={record.topCase || !isDouyin}
                      {...addfavourAuthConfig}
                      onClick={() => showCollectMaterialModal(record.itemId, 'item', MEDIA_KEY_ENUM.DOUYIN)}
                    >
                      <HeartOutlined />
                    </Link>
                  </Space>
                );
              },
            }),
          ].filter(Boolean) as TableCoumn[]
        }
      />
      <AddTagModal
        onTagSelect={(tagIds, targetId) => {
          updateTag(
            targetId,
            tagIds.map((v) => v[1]),
          );
        }}
      />
      <CollectMaterialModal onCollectedOk={refresh} />
    </FormCard>
  );
};

export default List;
