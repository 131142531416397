import { useSafeState } from 'ahooks';
import LineChart from './LineChart';
import List from './List';
import ProdDataIndex from './ProdDetailIndex';
import { ProCard } from '@ant-design/pro-components';
import { Blank } from '@/components/PageCard';
import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

export type DetailQueryFilter = {
  id: string;
  rangeDate?: string[];
};

type DataProps = {
  id: string;
};

export default ({ id }: DataProps) => {
  const [filter] = useSafeState<DetailQueryFilter>({ id });

  return (
    <>
      <ProCard
        {...headerFormCardProps}
        title={dict('INDICATOR_DATA')}
        subTitle={dict('MATRIX_OPERATION_STATISTICS_TIP')}
      >
        <ProdDataIndex filter={filter} />
      </ProCard>
      <Blank />
      <ProCard {...headerFormCardProps} title={dict('TREND_ANALYSIS')}>
        <LineChart filter={filter} />
      </ProCard>
      <Blank />
      <List filter={filter} />
    </>
  );
};
