import Text, { ArrLabelText } from '@/components/Form/Field/Text';
import PoiSetInput from '@/components/Form/Field/poi/RegionPoiModal';
import { OrgAccountTreeSelect } from '@/components/Form/Field/select/OrgAccountTreeSelect';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import {
  DyAllowDownloadVideoOptions,
  DyPublishViewPermissionOptions,
  PUBLISH_CONTENT_TYPE,
  columnConfig,
} from '@/pages/matrix-operation/publish/const';
import { Form, Input, Radio, TreeSelect } from '@antd';
import { PUBLISH_MOUMT_TYPE, PublishMountTypeOptions } from '../../../../const';
import { MicroAppInput } from './MicroAppInput';
import { FormOpType } from '@/constants/enum';
import { dict } from '@/hooks/useChangeLocale';
import { Blank } from '@/components/PageCard';

type PlatformConfigContentProps = {
  opType: FormOpType;
};

export const DouyinConfigContent = ({ opType }: PlatformConfigContentProps) => {
  const readonly = opType == FormOpType.VIEW;
  const form = Form.useFormInstance();
  const plartformName = 'douYinTaskDetailRequest';
  const componentType = Form.useWatch([plartformName, 'componentType'], form);
  const contentType = Form.useWatch('contentType', form);
  const isVideoUpload = contentType == PUBLISH_CONTENT_TYPE.VIDEO;
  const maxTitleSize = isVideoUpload ? 30 : 20;
  const captionRequiredTip = isVideoUpload ? dict('CAPTION_REQUIRED_TIP1') : dict('CAPTION_REQUIRED_TIP2');

  const accountId = Form.useWatch([plartformName, 'accountId'], form);
  const title = Form.useWatch([plartformName, 'title'], form);

  return (
    <>
      <Form.Item
        {...columnConfig}
        label={dict('PUBLISH_ACCOUNT')}
        name={[plartformName, 'accountId']}
        required
        rules={[{ required: true }]}
      >
        {readonly ? (
          dict('SEND_ACCOUNT_NUM_TIP', { num: accountId?.length })
        ) : (
          <OrgAccountTreeSelect
            placeholder={dict('PLEASE_SELECT_MULTIPLE_NAME', { name: dict('ACCOUNT') })}
            multiple
            treeDefaultExpandAll
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeCheckable
            filterMediaIds={MEDIA_KEY_ENUM.DOUYIN}
          />
        )}
      </Form.Item>
      <Form.Item
        {...columnConfig}
        label={dict('TITLE_OF_WORK')}
        name={[plartformName, 'title']}
        required
        rules={[{ required: true, max: maxTitleSize }]}
      >
        {readonly ? <Text /> : <Input placeholder={dict('INPUT_TEXT_VALIDATATION_TIP', { count: 30 })} />}
      </Form.Item>
      <Form.Item
        {...columnConfig}
        label={dict('WORK_INTRODUCTION')}
        name={[plartformName, 'caption']}
        required
        rules={[
          {
            required: true,
            validator(_, value) {
              if (isVideoUpload && value.length > 1000) return Promise.reject(captionRequiredTip);
              else if (!isVideoUpload && (title + value).length > 1000) return Promise.reject(captionRequiredTip);
              return Promise.resolve();
            },
          },
        ]}
      >
        {readonly ? <Text /> : <Input.TextArea placeholder={dict('WORK_INTRODUCTION_TYPE_INPUT_TIP', { num: 1000 })} />}
      </Form.Item>

      <Form.Item
        {...columnConfig}
        hidden={readonly && !componentType}
        label={dict('VIDEO_MOUNTING')}
        tooltip={dict('VIDEO_MOUNTING_TIP')}
      >
        <Form.Item hidden={readonly} noStyle name={[plartformName, 'componentType']}>
          {readonly ? (
            <ArrLabelText list={PublishMountTypeOptions} />
          ) : (
            <Radio.Group options={PublishMountTypeOptions} />
          )}
        </Form.Item>
        {componentType == PUBLISH_MOUMT_TYPE.MICROAPP && (
          <>
            <Blank />
            <Form.Item
              {...columnConfig}
              name={[plartformName, '_app']}
              required
              noStyle
              rules={[{ required: true, message: dict('SELECT_NAME', { name: 'APP' }) }]}
            >
              <MicroAppInput placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: 'APP' })} readOnly={readonly} />
            </Form.Item>
          </>
        )}
        {componentType == PUBLISH_MOUMT_TYPE.GEOGRAPHIC_LOCATION && (
          <>
            <Blank />
            <Form.Item
              {...columnConfig}
              name={[plartformName, '_poi']}
              required
              noStyle
              rules={[{ required: true, message: dict('SELECT_NAME', { name: dict('POI_POSITION') }) }]}
            >
              <PoiSetInput
                placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('POI_POSITION') })}
                readOnly={readonly}
              />
            </Form.Item>
          </>
        )}
      </Form.Item>

      <Form.Item
        {...columnConfig}
        label={dict('ALLOW_SAVING_VIDEO')}
        initialValue={opType == FormOpType.CREATE ? DyAllowDownloadVideoOptions[0].value : undefined}
        name={[plartformName, 'download']}
        required
      >
        {readonly ? (
          <ArrLabelText list={DyAllowDownloadVideoOptions} />
        ) : (
          <Radio.Group options={DyAllowDownloadVideoOptions} />
        )}
      </Form.Item>

      <Form.Item
        {...columnConfig}
        label={dict('PERMISSION_SETTING')}
        name={[plartformName, 'viewPermission']}
        required
        initialValue={opType == FormOpType.CREATE ? DyPublishViewPermissionOptions[0].value : undefined}
      >
        {readonly ? (
          <ArrLabelText list={DyPublishViewPermissionOptions} />
        ) : (
          <Radio.Group options={DyPublishViewPermissionOptions} />
        )}
      </Form.Item>
    </>
  );
};
