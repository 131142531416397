import React, { Key } from 'react';
import { LeftNav, List } from './NavTableList';
import { useEventEmitter } from 'ahooks';
import { ProCard } from '@ant-design/pro-components';

export default ({ id }: { id: Key }) => {
  const event$ = useEventEmitter();

  return (
    <div className="page-container" style={{ maxHeight: 680, border: '1px solid #ececec', overflow: 'hidden' }}>
      <LeftNav id={id} event$={event$} />
      <ProCard>
        <List id={id} event$={event$} />
      </ProCard>
    </div>
  );
};
