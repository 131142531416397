import Text from '@/components/Form/Field/Text';
import { defaultFormItemConfig } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { getPublishDouyinAnchorSearch } from '@/services/item/publish';
import { App, Flex, Form, Image, Input, InputProps, Modal, ModalProps, Select, Typography } from '@antd';
import { useBoolean, useDebounceEffect } from 'ahooks';
import type { DefaultOptionType } from 'antd/es/select';
import { get } from 'lodash';
import { ReactNode, useState } from 'react';

type DouyinAppPickModalProps = {
  children: ReactNode;
  defalutAppLink?: string;
  onAppSelect: (app) => void;
} & ModalProps;

export const DouyinAppPickModal: React.FC<DouyinAppPickModalProps> = ({
  children,
  onAppSelect,
  // accountId,
  defalutAppLink,
  ...params
}) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [form] = Form.useForm();
  const appLink = Form.useWatch('appLink', form);

  const { user } = useAppContext();
  const [options, setOptions] = useState<Option[]>();
  const [loading, setLoading] = useState(false);

  const { message } = App.useApp();

  useDebounceEffect(() => {
    if (!appLink) return;

    try {
      if (!appLink.trim().startsWith('https://') || appLink.includes('&token=')) throw dict('ERROR_LINK_FORMAT_TIP');
    } catch (error) {
      message.error(error as string);
      setOptions([]);
      return;
    }

    setLoading(true);
    //测试LINK : https://www.iesdouyin.com/share/microapp/?token=8142295d35a103df1c0456283b292788&share_channel=copy
    getPublishDouyinAnchorSearch(user?.tenantId, appLink, user?.bid)
      .then((datas) => {
        const { anchor_list, status_code, status_msg } = datas;
        if (status_code != 0) {
          message.error(status_msg);
          return;
        }

        const options = (anchor_list || []).map((v) => ({
          label: v.name,
          value: v.id,
          // disabled: v.enable_mount,
          ...v,
        }));
        setOptions(options);
      })
      .catch((error) => {
        message.error(error);
      })
      .finally(() => setLoading(false));
  }, [user, appLink]);

  return (
    <>
      <span onClick={showModal}>{children}</span>
      <Modal
        title={dict('SELECT_MINI_APP')}
        open={isModalOpen}
        onCancel={hideModal}
        destroyOnClose
        {...params}
        onOk={async () => {
          try {
            const { appId, appLink } = await form.validateFields();
            const app = options?.find((v) => v.value == appId);
            onAppSelect({ ...app, appLink });
            hideModal();
          } catch (error) {}
        }}
      >
        <Form {...defaultFormItemConfig} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} form={form}>
          <Form.Item
            name="appLink"
            label={dict('LINK_URL')}
            required
            rules={[{ required: true }]}
            initialValue={defalutAppLink}
          >
            <Input placeholder={dict('PLEASE_COPY_DY_APP_LINK')} />
          </Form.Item>
          <Form.Item
            name="appId"
            label={dict('APPLICATION_INFO')}
            required
            rules={[
              {
                required: true,
                message: dict('PLEASE_SELECT_APPLICATION'),
              },
            ]}
          >
            <Select
              options={options as DefaultOptionType[]}
              placeholder={dict('PLEASE_SELECT_APPLICATION')}
              // labelInValue
              loading={loading}
              allowClear
              optionRender={(option) => {
                return (
                  <Flex gap={10}>
                    <Image width={40} src={get(option, 'data.poster.url_list.[0]', '')} preview={false} />
                    <div>
                      <Typography.Text strong>{option.label}</Typography.Text>
                      <br />
                      <Typography.Text>{get(option, 'data.summary', '')}</Typography.Text>
                    </div>
                  </Flex>
                );
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

type DouyinAppVale = {
  appAddress: string;
  appContent: string;
  appId: string;
  appType: string;
  appName: string;
};
type MicroAppInputProps = {
  // accountId: number | undefined;
  onChange?: (value) => void;
  value?: DouyinAppVale;
} & InputProps;

const convertAppInfo = (app) => {
  if (!app) return null;
  const { name, value, appLink, extra, type } = app;
  return {
    appId: value,
    appName: name,
    appContent: extra,
    appType: type,
    appAddress: appLink,
  };
};
export const MicroAppInput: React.FC<MicroAppInputProps> = ({ value, onChange, readOnly, ...props }) => {
  const showStr = value ? value.appName : undefined;
  return readOnly ? (
    <Text value={showStr} />
  ) : (
    <DouyinAppPickModal
      onAppSelect={(app) => {
        onChange?.(convertAppInfo(app));
      }}
    >
      <Input
        {...props}
        title={showStr}
        readOnly
        value={showStr}
        addonAfter={
          value ? (
            <a
              onClick={(e) => {
                onChange?.(undefined);
                e.stopPropagation();
              }}
            >
              {dict('CLEAR')}
            </a>
          ) : undefined
        }
      />
    </DouyinAppPickModal>
  );
};
