import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { DATE_FORMAT_STR, DateUtils } from '@/utils/dateUtil';
import { DatePicker, Radio, Space } from '@antd';
import { dict } from '@/hooks/useChangeLocale';
import { get, isEmpty } from 'lodash';

const { RangePicker } = DatePicker;

const today = dayjs();
const before1Day = today.subtract(1, 'day');
const before7Day = today.subtract(7, 'day');
const before30Day = today.subtract(30, 'day');
const endDayStr = today.format(DATE_FORMAT_STR);

const dateRangePickValues = [
  [[before1Day.format(DATE_FORMAT_STR), endDayStr], dict('LAST_NUM_DAYS', { num: 1 })],
  [[before7Day.format(DATE_FORMAT_STR), endDayStr], dict('LAST_NUM_DAYS', { num: 7 })],
  [[before30Day.format(DATE_FORMAT_STR), endDayStr], dict('LAST_NUM_DAYS', { num: 30 })],
];

type RecentDatePickBtnType = {
  value?: string[];
  onChange?: (val: string[]) => void;
};
const RecentDateBtnPick: React.FC<RecentDatePickBtnType> = ({ value, onChange }) => {
  return (
    <Space>
      <Radio.Group
        value={value ? value.join('~') : null}
        onChange={(e) => {
          onChange?.(get(e, 'target.value', '').split('~'));
        }}
      >
        {dateRangePickValues.map(([key, label], index) => (
          <Radio.Button key={index} value={(key as []).join('~')}>
            <span style={{ margin: '0 5px' }}>{label}</span>
          </Radio.Button>
        ))}
      </Radio.Group>
    </Space>
  );
};

type RecentDatePickType = { value?: string[]; onChange?: (v: string[]) => void };

export default (): [React.FC<RecentDatePickType>] => {
  const Picker = useMemoizedFn(({ value: date, onChange }: RecentDatePickType) => {
    return (
      <>
        <RecentDateBtnPick value={date} onChange={onChange} />
        <RangePicker
          style={{ width: 220, marginLeft: 10 }}
          value={isEmpty(date) ? null : [dayjs(date?.[0]), dayjs(date?.[1])]}
          onChange={(e) => {
            onChange?.(e ? DateUtils.convertRangeStr(e) : []);
          }}
        />
      </>
    );
  });

  return [Picker];
};
