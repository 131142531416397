import { AccountAvator } from '@/components/AccountAvator';
import { FormCard } from '@/components/PageCard';
import { VideoCoverImg } from '@/components/VideoCoverImg';
import { FAULT_IMAGE, ROUTE_PATH } from '@/constants/const';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getReportSummary } from '@/services/item';
import { Image, Space, Spin, Table, Typography } from '@antd';
import { useRequest, useSafeState } from 'ahooks';
import { VIDEO_NOTE_TYPE, VIDEO_NOTE_TYPE_NAME, convertDbFilterToFilter, getSoretParams } from '../../../const';
import { downloadXhsReportSummary } from '@/services/item/xhs';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { DateUtils } from '@/utils/dateUtil';
import { dict } from '@/hooks/useChangeLocale';
import { QueryReportParams } from '@/services/item/type';

type ListPros = {
  params: RequestSearchParams;
  type?: VIDEO_NOTE_TYPE;
};
const { Link } = Typography;

const List = ({ params, type }: ListPros) => {
  // const { user } = useAppContext();
  // const { message } = App.useApp();
  const [downloadParams, setDownloadParams] = useSafeState<RequestSearchParams>({});

  const { refresh: download, loading: downloadLoading } = useRequest(
    () => downloadXhsReportSummary(downloadParams as QueryReportParams),
    {
      manual: true,
    },
  );

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'publishTime', order: 'descend' } }) => {
      const { publish_time, tagValueIds, ...rest } = params;
      const [startTime, endTime] = publish_time ?? [];
      const downloadParams = {
        startTime,
        endTime,
        tagValueIds: tagValueIds?.map((v) => v[1]),
        noteType: type,
        ...getSoretParams(sorter),
        ...convertDbFilterToFilter(rest),
      };
      setDownloadParams(downloadParams);
      return getReportSummary({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params, type], debounceWait: 100 },
    type,
  );

  // const { run: updateTag, loading: updateTagLoading } = useRequest(
  //   async (itemId, tagIds) => {
  //     try {
  //       await updateItemTag(itemId, tagIds, user?.bid);
  //       message.success(dict('OPERATION_SUCCESS'));
  //       refresh();
  //     } catch (error) {
  //       message.error(dict('OPERATION_FAILURE'));
  //     }
  //   },
  //   {
  //     refreshDeps: [user],
  //     manual: true,
  //   },
  // );
  // const [AddTagModal, showTagModal] = useAddTagModal();

  const ROUTE_PRODUCTION_PATH = `/${ROUTE_PATH.MATRIX}/${ROUTE_PATH.PRODUCTION}`;

  const { getBtnPermissionConfig } = usePermission();
  const viewAuthConfig = getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.VIEW);

  return (
    <FormCard
      title={dict('LIST_WORKS')}
      style={{ marginBottom: 10 }}
      boxShadow={false}
      extra={
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      }
    >
      <Table
        {...tableProps}
        // loading={tableProps.loading || updateTagLoading}
        scroll={{ x: 1200, y: 500 }}
        columns={
          [
            getTableColumn({
              title: dict('COVER'),
              dataIndex: 'cover',
              width: 100,
              render: (url, record) =>
                record.noteType == VIDEO_NOTE_TYPE.NORMAL ? (
                  <Image
                    preview={false}
                    style={{ width: 80, borderRadius: 5 }}
                    fallback={FAULT_IMAGE}
                    referrerPolicy="same-origin"
                    src={record.coverOssUrl || url}
                  />
                ) : (
                  <VideoCoverImg
                    style={{ width: 80, borderRadius: 10 }}
                    itemId={record.itemId}
                    src={record.coverOssUrl || url}
                  />
                ),
            }),
            getTableColumn({
              title: dict('TITLE_OF_WORK'),
              dataIndex: 'title',
              width: 150,
              render: (value) => (
                <Typography.Paragraph ellipsis={{ rows: 2, tooltip: true }}>{value}</Typography.Paragraph>
              ),
            }),
            getTableColumn({
              title: dict('PUBLISH_ACCOUNT'),
              dataIndex: 'accountName',
              width: 150,
              render: (_, record) => <AccountAvator avaterUrl={record?.avatar} name={record?.accountName} />,
            }),
            getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),
            getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName' }),
            getTableColumn({ title: dict('DEALER_CODE'), dataIndex: 'distributorCode' }),
            // getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
            // getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
            // getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),
            // getTableColumn({
            //   title: dict('TAG'),
            //   dataIndex: 'tagInfoList',
            //   width: 200,
            //   ellipsis: true,
            //   render: (tagInfoList) =>
            //     isEmpty(tagInfoList) ? '-' : tagInfoList?.map((v) => `${v.tagName}-${v.tagValueName}`).join(','),
            // }),
            getTableColumn({
              title: dict('NOTE_TYPE'),
              dataIndex: 'noteType',
              sorter: true,
              render(noteType) {
                return VIDEO_NOTE_TYPE_NAME[noteType];
              },
            }),
            getTableColumn({ title: dict('VIEW_NUM'), dataIndex: 'playCount', sorter: true }, type),
            getTableColumn({ title: dict('COMMENT_TOTAL'), dataIndex: 'commentCount', sorter: true }, type),
            getTableColumn({ title: dict('LIKE_TOTAL'), dataIndex: 'likeCount', sorter: true }, type),
            getTableColumn({ title: dict('SHARE_TOTAL'), dataIndex: 'shareCount', sorter: true }, type),
            getTableColumn({ title: dict('COLLECTIONS_NUM'), dataIndex: 'fav', sorter: true }, type),
            type == VIDEO_NOTE_TYPE.NORMAL
              ? null
              : getTableColumn({ title: dict('BULLET_COMMENTS_NUM'), dataIndex: 'danmaku', sorter: true }, type),
            type == VIDEO_NOTE_TYPE.NORMAL
              ? null
              : getTableColumn(
                  {
                    title: dict('AVG_WATCH_TIME'),
                    dataIndex: 'avgPlayTimeSec',
                    sorter: true,
                    width: 150,
                    render: (_, record) => dict('NUM_SECOND', { num: record?.avgPlayTimeSec }),
                  },
                  type,
                ),
            getTableColumn(
              { title: dict('INCREASING_FOLLOWERS'), dataIndex: 'followCount', width: 150, sorter: true },
              type,
            ),
            getTableColumn({
              title: dict('PUBLISH_TIME'),
              dataIndex: 'publishTime',
              align: 'center',
              width: 120,
              sorter: true,
              defaultSortOrder: 'descend',
              render: (v) => DateUtils.formatDateTime(v),
            }),
            getTableColumn({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              fixed: 'right',
              width: 90,
              render: (_, record) => {
                const url = `${ROUTE_PRODUCTION_PATH}/${ROUTE_PATH.XIAOHONGSHU}/${
                  ROUTE_PATH.DETAIL
                }/${encodeURIComponent(record.itemId)}`;

                return (
                  <Space>
                    <a
                      title={dict('ACTION_VIEW_INTERACTIVE')}
                      {...viewAuthConfig}
                      onClick={() => window.open(`${BASE_PATH}${url}?type=video-detail`)}
                    >
                      {dict('ACTION_VIEW')}
                    </a>
                    <a
                      title={dict('ACTION_VIEW_COMMENT')}
                      {...viewAuthConfig}
                      onClick={() => window.open(`${BASE_PATH}${url}?type=comment`)}
                    >
                      {dict('COMMENT')}
                    </a>
                    {/* <a
                      title={dict('ACTION_ADD_NAME')}
                      onClick={() => {
                        showTagModal(
                          record?.tagInfoList?.filter((v) => v.type == 'manual').map((v) => [v.tagId, v.tagValId]),
                          record?.tagInfoList?.filter((v) => v.type == 'automatic').map((v) => [v.tagId, v.tagValId]),
                          record.itemId,
                        );
                      }}
                    >
                      {dict('ACTION_ADD_NAME', { name: dict('TAG') })}
                    </a> */}
                  </Space>
                );
              },
            }),
          ].filter((v) => v) as TableCoumn[]
        }
      />
      {/* <AddTagModal
        onTagSelect={(tagIds, targetId) => {
          updateTag(
            targetId,
            tagIds.map((v) => v[1]),
          );
        }}
      /> */}
    </FormCard>
  );
};

export default List;
